import { CraqService } from "craq";
import cookie from "cookie";

export type GetCookieServicePayload = {
  name: string;
};

const getCookieService: CraqService<GetCookieServicePayload> = (
  context,
  { name }
) => {
  const cookies = cookie.parse(
    (typeof window === "undefined"
      ? // @ts-ignore
        context.ctx.req.headers.Cookie
      : document.cookie) || ""
  );

  return Promise.resolve(cookies[name]);
};

export default getCookieService;
