import { createReducer, Reducer } from "@reduxjs/toolkit";

import { ApplicationReducerType, ListingType, Breakpoint } from "./types";
import {
  ConfigActions,
  listingTypeChanged,
  breakpointChanged,
} from "./actions";

const applicationReducer: Reducer<ApplicationReducerType, ConfigActions> =
  createReducer(
    {
      listingType: ListingType.Pagination,
      breakpoint: Breakpoint.DesktopMedium,
    } as ApplicationReducerType,
    (builder) => {
      builder
        .addCase(listingTypeChanged, (state, { payload }) => {
          state.listingType = payload;
        })
        .addCase(breakpointChanged, (state, { payload }) => {
          state.breakpoint = payload;
        });
    }
  );
export default applicationReducer;
