import React, { PropsWithChildren, MouseEvent } from "react";
import { Route } from "router6";
import { Link } from "router6-react";

import Chip from "../Chip";
import isTagLinkActive, { getTagsFromParam } from "../../utils/isTagLinkActive";

const Tag = ({
  section,
  tag,
  children,
  onClick,
  badge,
  tagName,
}: PropsWithChildren<{
  badge?: string;
  tag: string;
  tagName?: string;
  section: string;
  onClick?: (e: MouseEvent<HTMLAnchorElement>, route: Route) => void;
}>) => (
  <Link
    to="section.tag"
    params={{ tag, section }}
    onClick={onClick}
    tagName={tagName}
  >
    {({ currentRoute }) => (
      <Chip
        badge={badge}
        active={isTagLinkActive(
          getTagsFromParam(tag).reverse()[0],
          currentRoute
        )}
      >
        {children}
      </Chip>
    )}
  </Link>
);

export default Tag;
