import React from 'react';

import s from './styles.css';

const toggleSidebar = ({ target: { checked } }) => {
  const sidebar = document.getElementById('sidebar');

  if (checked) {
    sidebar.setAttribute('opened', 'true');
  } else {
    sidebar.removeAttribute('opened');
  }
};

const MenuButton = () => (
  <label className={s.label}>
    <input
      type="checkbox"
      style={{ display: 'none' }}
      onChange={toggleSidebar}
    />
    <button className={s.sidebarToggle}>
      <span>Menu</span>
    </button>
  </label>
);

export default MenuButton;
