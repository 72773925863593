import { NavigateAction, Action } from "../../types";
import {
  tagsLoadingSuccess,
  tagsLoadingStart,
  typedTagsLoadingSuccess,
} from "../../store/reducers/tags/actions";
import { sectionsToGender } from "../../utils/sectionToGender";
import apiService from "../../services/apiService";

type Payload = {
  gender?: string;
  tag?: string[];
  limit?: number;
  replace?: boolean;
  typed?: boolean;
};

const tagsListAction: Action<Payload> = (
  context,
  { replace, typed, ...payload }
) =>
  context
    .dispatch(tagsLoadingStart())
    .then(() =>
      context.service(apiService, {
        method: "tags",
        query: { ...payload, typed },
      })
    )
    .then(({ payload }) =>
      context.dispatch(
        typed
          ? typedTagsLoadingSuccess({ list: payload, replace })
          : tagsLoadingSuccess({ list: payload, replace })
      )
    );

export const tagsListNavigateAction: NavigateAction = (
  context,
  { route: { params } }
) => {
  const gender = sectionsToGender(String(params.section));
  const limit = Number(params.limit) || 50;
  const typedTagsLoaded = context.getState().tags.listTyped.length > 0;

  return Promise.all([
    context.action(tagsListAction, {
      gender,
      limit,
      typed: false,
      replace: true,
    }),
    typedTagsLoaded
      ? Promise.resolve()
      : context.action(tagsListAction, {
          limit: 300,
          typed: true,
          replace: true,
        }),
  ]);
};

export default tagsListAction;
