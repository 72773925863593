import { useRouter } from "router6-react";
import { CraqReactReduxProvider } from "craq-react-redux";
import { Context } from "craq";
import React, { ComponentType, PropsWithChildren } from "react";
import { Route } from "router6";

type PageComponentType = ComponentType<{ route: Route }>;
type LayoutComponentType = ComponentType<PropsWithChildren>;
const DefaultPageComponent: PageComponentType = ({ route }) =>
  `${route.name} page stub`;
const DefaultLayoutComponent: LayoutComponentType = ({ children }) => children;

const Application = ({ context }: { context: Context<any, any> }) => {
  const { currentRoute } = useRouter();

  const PageComponent =
    context.getComponent<PageComponentType>(currentRoute.config.page, "page") ||
    DefaultPageComponent;

  const LayoutComponent =
    context.getComponent<ComponentType<PropsWithChildren>>(
      currentRoute.config.layout,
      "layout"
    ) || DefaultLayoutComponent;

  return (
    <CraqReactReduxProvider store={context.getStore()}>
      <LayoutComponent>
        <PageComponent route={currentRoute} />
      </LayoutComponent>
    </CraqReactReduxProvider>
  );
};

export default Application;
