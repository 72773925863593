import React from "react";

import Section from "./Section";
import TopSection from "./TopSection";
import { SidebarSection, SidebarTopItem } from "./types";
import s from "./styles.css";

const Sidebar = ({
  sections,
  topItems,
}: {
  sections: SidebarSection[];
  topItems: SidebarTopItem[];
}) => (
  <aside className={s.root} id="sidebar">
    <div className={s.fixed}>
      <TopSection items={topItems} />
      {sections.map(({ title, items, opened }) => (
        <Section key={title} title={title} items={items} opened={opened} />
      ))}
    </div>
    <footer className={s.footer}>{/*<ListingTypeControl />*/}</footer>
  </aside>
);

export default Sidebar;
