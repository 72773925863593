import { createReducer, Reducer } from "@reduxjs/toolkit";

import {
  modelsLoadingStart,
  modelsLoadingSuccess,
  ModelsActions,
  modelsFavoritesUpdated,
  modelsSearchStart,
  modelsSearchSuccess,
  modelsCountLoaded,
} from "./actions";
import { Model, ModelsReducerType } from "./types";

const getKey = (model: Model) => `${model.platform}_${model.username}`;
const merge = (listA: Model[], listB: Model[]) => {
  const hashB = listB.reduce((result, model) => {
    result[getKey(model)] = model;

    return result;
  }, {});

  return listA
    .map((modelA) => {
      const key = getKey(modelA);

      if (key in hashB) {
        const modelB = hashB[key];

        delete hashB[key];

        return {
          ...modelA,
          ...modelB,
        };
      }

      return modelA;
    })
    .concat(Object.values(hashB));
};

const modelsReducer: Reducer<ModelsReducerType, ModelsActions> = createReducer(
  {
    loading: false,
    onlineCount: 0,
    search: false,
    favorites: [],
    searched: [],
    list: [],
    page: 1,
    finished: false,
    total: 0,
  } as ModelsReducerType,
  (builder) => {
    builder
      .addCase(modelsSearchStart, (state) => {
        state.search = true;
      })
      .addCase(modelsSearchSuccess, (state, { payload }) => {
        state.searched = payload;
        state.search = false;
      })
      .addCase(modelsLoadingStart, (state) => {
        state.loading = true;
      })
      .addCase(modelsFavoritesUpdated, (state, { payload }) => {
        state.favorites = payload;
      })
      .addCase(modelsCountLoaded, (state, { payload }) => {
        state.onlineCount = payload;
      })
      .addCase(
        modelsLoadingSuccess,
        (
          state,
          { payload: { list, replace, limit, page = state.page, total } }
        ) => ({
          ...state,
          finished: limit > list.length,
          page,
          total,
          list: replace ? list : merge(state.list, list),
          loading: false,
        })
      );
  }
);

export default modelsReducer;
