import { actions, components } from "../registries";
import NotFoundPage from "../pages/NotFound";
import InternalErrorPage from "../pages/InternalError";
import { tagsListNavigateAction } from "../actions/tags/list";
import setupMetaAction from "../actions/meta/setup";
import gaMetaAction from "../actions/meta/ga";
import envMetaAction from "../actions/meta/env";
import maintainVersionMetaAction from "../actions/meta/maintainVersion";
import navigateModelsStatsAction from "../actions/models/stats";
import readBreakpointAction from "../actions/ui/readBreakpoint";

const bundles = {
  home: () =>
    import(
      /* webpackChunkName: "home" */
      /* webpackMode: "lazy" */
      "./home"
    ),
  model: () =>
    import(
      /* webpackChunkName: "model" */
      /* webpackMode: "lazy" */
      "./model"
    ),
};

export default bundles;

actions.register("tags/list", tagsListNavigateAction);
actions.register("meta/setup", setupMetaAction);
actions.register("meta/maintainVersion", maintainVersionMetaAction);
actions.register("meta/ga", gaMetaAction);
actions.register("meta/env", envMetaAction);
actions.register("models/stats", navigateModelsStatsAction);
actions.register("ui/breakpoint", readBreakpointAction);

components.register("page/404", NotFoundPage);
components.register("page/5xx", InternalErrorPage);
