import React, { PropsWithChildren } from "react";
import { useRouter } from "router6-react";
import { BsStars } from "react-icons/bs";
import Container from "../../components/Grid/Container";
import Root from "../../components/Root";

import s from "./styles.css";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MenuButton from "../../components/Sidebar/MenuButton";
import { useAppSelector } from "../../connect";
import { State } from "../../store/types";
import { Tag } from "../../store/reducers/tags/types";
import { createSelector } from "reselect";
import isTagLinkActive from "../../utils/isTagLinkActive";

const order = ["age", "ethnicity", "body-type", "hair-color", "language"];
const tagsSelector = createSelector(
  ({ tags }: State) => tags.listTyped,
  (tags) =>
    tags.reduce((result, tag) => {
      if (tag.type !== "gender") {
        result[tag.type] = [...(result[tag.type] || []), tag];
      }
      return result;
    }, {} as Record<string, Tag[]>)
);
const MainLayout = ({ children }: PropsWithChildren<{}>) => {
  const { currentRoute } = useRouter();

  const tagsByType = useAppSelector(tagsSelector);

  return (
    <Root>
      <Header>
        <MenuButton />
      </Header>
      <div className={s.body}>
        <Sidebar
          topItems={[
            {
              icon: BsStars,
              title: "Most viewed",
              routeName: "section.tag",
              params: {
                tag: "popular",
                section: currentRoute.params.section || "models",
              },
            },
          ]}
          sections={Object.entries(tagsByType)
            .sort(([a], [b]) => order.indexOf(a) - order.indexOf(b))
            .map(([type, tags]) => ({
              opened: tags.some(({ name }) =>
                isTagLinkActive(name, currentRoute)
              ),
              title: type,
              items: tags.map(({ name }) => ({
                title: name,
                routeName: "section.tag",
                params: {
                  tag: name,
                  section: currentRoute.params.section || "models",
                },
              })),
            }))}
        />
        <main className={s.content}>
          <Container>{children}</Container>
        </main>
      </div>
    </Root>
  );
};

export default MainLayout;
