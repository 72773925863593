import { RouteMiddleware } from "router6";

import { getEnv } from "../frontend/env";

const googleAnalyticsMiddleware: RouteMiddleware =
  () =>
  ({ to }, next) => {
    const gaId = getEnv("GOOGLE_ANALYTICS_ID");

    if (typeof window.gtag === "function" && gaId) {
      window.gtag("config", gaId, { page_path: to.path });
    }

    return next();
  };

export default googleAnalyticsMiddleware;
