import { createReducer } from "@reduxjs/toolkit";

import { seoLoaded } from "./actions";
import { SeoReducerType } from "./types";

const seoReducer = createReducer<SeoReducerType>(
  {} as SeoReducerType,
  (builder) => {
    builder.addCase(seoLoaded, (state, { payload }) => {
      state.title = payload.title;
      state.description = payload.description;
      state.header = payload.header;
      state.subheader = payload.subheader;
      state.keywords = payload.keywords;
      state.footed = payload.footed;
    });
  }
);
export default seoReducer;
