import React from 'react';

import s from './styles.css';

export default (Icon) =>
  ({ size }: { size: number }) =>
    (
      <span className={s.root} style={{ fontSize: size }}>
        <Icon size={size} />
      </span>
    );
