import React from "react";
import { HiOutlineStatusOnline } from "react-icons/hi";
import { useAppSelector } from "../../../connect";
import getOnlineModelsCount from "../../../store/reducers/models/selectors/getOnlineModelsCount";

import s from "./styles.css";

const OnlineCount = () => {
  const count = useAppSelector(getOnlineModelsCount);

  return (
    <div className={s.root}>
      <HiOutlineStatusOnline size={32} />
      &nbsp;{count} online
    </div>
  );
};

export default OnlineCount;
