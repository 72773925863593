import Router6 from "router6";
import historyMiddleware from "router6-history";
import { createCraqClient, configureContext } from "craq-client";
import webpackBuildClientMiddleware from "craq-webpack/dist/client";
import getReactRenderer from "craq-react-renderer/dist/client";

import routes from "../frontend/routes";
import { actions, components } from "../frontend/registries";
import Application from "../frontend/Application";
import store from "../frontend/store";
import bundles from "../frontend/bundles";
import "./global.css";
import breakpointHandling from "./breakpointHandling";
import googleAnalyticsMiddleware from "./googleAnalyticsMiddleware";

const context = configureContext({
  actions,
  components,
  getRouter: () =>
    new Router6(routes)
      .use(webpackBuildClientMiddleware({ bundles }))
      .use(googleAnalyticsMiddleware)
      .use(historyMiddleware()),
  getStore: () => store,
});

breakpointHandling(context);

const node = document.getElementById("root");
const renderers = { react: getReactRenderer(Application, { node }) };

createCraqClient(context, { renderers }).run(document.location.href).render();
