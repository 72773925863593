import React, { useState } from "react";
import cn from "classnames";
import { HiOutlineChevronDown } from "react-icons/hi";
import { Link, useRouter } from "router6-react";

import { SidebarSectionItem } from "../types";
import getTagClickHandler from "../../../utils/handleTagClick";
import isTagLinkActive from "../../../utils/isTagLinkActive";
import formatTag from "../../../utils/formatTag";

import s from "./styles.css";

const Section = ({
  title,
  items,
  opened: propsOpened,
}: {
  title: string;
  items: SidebarSectionItem[];
  opened: boolean;
}) => {
  const [opened, setOpened] = useState(propsOpened);
  const router = useRouter();
  const handleTagClick = getTagClickHandler(router.currentRoute);
  const id = `s-${title}`;

  return (
    <div className={s.root}>
      <input
        checked={opened}
        className={s.checkbox}
        type="checkbox"
        id={id}
        onChange={({ target }) => setOpened(target.checked)}
      />
      <label className={s.head} htmlFor={id}>
        <h5 className={s.title}>{formatTag(title)}</h5>
        <HiOutlineChevronDown />
      </label>

      <ul className={s.list}>
        {items.map(({ title, routeName, params }) => (
          <li key={title}>
            <Link to={routeName} params={params} onClick={handleTagClick}>
              {({ currentRoute }) => (
                <div
                  className={cn(s.listItem, s.link, {
                    [s.linkActive]: isTagLinkActive(
                      String(params.tag),
                      currentRoute
                    ),
                  })}
                >
                  {formatTag(title)}
                </div>
              )}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Section;
