import { createReducer, Reducer } from "@reduxjs/toolkit";

import { ModalActions, modalDisplayed, modalHidden } from "./actions";
import { ModalReducerType } from "./types";

const modalReducer: Reducer<ModalReducerType, ModalActions> = createReducer(
  { list: [] },
  (builder) => {
    builder
      .addCase(modalHidden, (state, { payload }) => ({
        ...state,
        list: state.list.filter((modalId) => modalId !== payload),
      }))
      .addCase(modalDisplayed, (state, { payload }) => ({
        ...state,
        list: [...state.list, payload],
      }));
  }
);
export default modalReducer;
