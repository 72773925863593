import React from "react";
import { useRouter } from "router6-react";

import Tag from "../Tag";
import getTagClickHandler from "../../utils/handleTagClick";
import formatTag from "../../utils/formatTag";
import { Tag as TagType, TypedTag } from "../../store/reducers/tags/types";

import s from "./styles.css";

const TagsList = ({
  section = "models",
  tags,
  typedTag,
}: {
  section?: string;
  tags: TagType[];
  typedTag?: TypedTag;
}) => {
  const { currentRoute } = useRouter();
  const handleTagClick = getTagClickHandler(currentRoute, typedTag);

  return (
    <ul className={s.root}>
      {tags.map(({ name }) => (
        <li className={s.tag} key={name}>
          <Tag
            tag={[typedTag?.name, name].filter(Boolean).join("+")}
            section={section}
            onClick={handleTagClick}
          >
            {formatTag(name)}
          </Tag>
        </li>
      ))}
    </ul>
  );
};

export default TagsList;
