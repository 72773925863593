import React, { PropsWithChildren, ReactNode } from "react";
import { Link } from "router6-react";

import Thumb from "../Thumb";
import { Model } from "../../store/reducers/models/types";
import { State } from "../../store/types";

import s from "./styles.css";
import { useAppSelector } from "../../connect";

type Selector = (state: State) => Model[];

const ThumbsList = ({
  selector,
  limit,
  children,
  title = null,
  perRow = null,
}: PropsWithChildren<{
  selector: Selector;
  title?: ReactNode;
  limit?: number;
  perRow?: number;
}>) => {
  const models = useAppSelector(selector);

  return (
    <>
      {models.length > 0 && title}
      <div className={s.root} data-per-row={perRow}>
        {(limit ? models.slice(0, limit) : models).map((model) => (
          <Link
            key={`${model.username}-${model.platform}`}
            to="model"
            params={{ username: model.username, platform: model.platform }}
            className={s.model}
          >
            <Thumb model={model} />
          </Link>
        ))}
        {children}
      </div>
    </>
  );
};

export default ThumbsList;
