import { Action } from "../../types";

import { Breakpoint } from "../../store/reducers/application/types";
import { breakpointChanged } from "../../store/reducers/application/actions";
import setCookieService from "../../services/setCookieService";

const changeBreakpointAction: Action<Breakpoint> = (context, breakpoint) =>
  context.dispatch(breakpointChanged(breakpoint)).then(() =>
    context.service(setCookieService, {
      name: "breakpoint",
      value: String(breakpoint),
    })
  );

export default changeBreakpointAction;
