// @ts-nocheck
import { NavigateAction } from "../../types";
import { formatWithParams } from "../../utils/meta";
import apiService from "../../services/apiService";
import { getEnv } from "../../env";
import { seoLoaded } from "../../store/reducers/seo/actions";
import { getTagsFromRoute } from "../../utils/isTagLinkActive";

const seoCoveredRoutes = ["section", "section.tag", "home"];

const setupMetaAction: NavigateAction = (
  context,
  { route: { name, config, params, path }, route }
) =>
  (seoCoveredRoutes.includes(name)
    ? context.service(apiService, {
        method: `seo/${params.section || "models"}`,
        query: {
          tag: getTagsFromRoute(route),
        },
      })
    : Promise.resolve({})
  )
    .then(({ payload }) => ({ ...config.seo, ...payload }))
    .then((seo) => {
      const title = formatWithParams(seo.title || "", params);
      const description = formatWithParams(seo.description || "", params);

      context.head.setTitle([getEnv("SITE_NAME"), title].join(" | "));
      context.head.setLink({
        rel: "canonical",
        href: `${getEnv("FRONT_ORIGIN")}${path}`,
      });
      context.head.setMeta({ name: "description", content: description });
      context.head.setMeta({ property: "og:title", content: title });
      context.head.setMeta({
        property: "og:description",
        content: description,
      });
      context.head.setMeta({ property: "twitter:title", content: title });
      context.head.setMeta({
        property: "twitter:description",
        content: description,
      });

      return context.dispatch(seoLoaded(seo));
    });

export default setupMetaAction;
