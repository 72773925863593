import { createAction } from "@reduxjs/toolkit";

import { Breakpoint, ListingType } from "./types";

export const listingTypeChanged = createAction<ListingType>(
  "listing type changed"
);
export const breakpointChanged = createAction<Breakpoint>("breakpoint changed");

export type ConfigActions =
  | ReturnType<typeof listingTypeChanged>
  | ReturnType<typeof breakpointChanged>;
