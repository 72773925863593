import React from "react";
import { BiWebcam } from "react-icons/bi";

import Container from "../Grid/Container";
import Row from "../Grid/Row";
import Column from "../Grid/Column";
import ThumbsList from "../ThumbsList";
import getModelsList from "../../store/reducers/models/selectors/getModelsList";
import MainTagsList from "../MainTagsList";
import { State } from "../../store/types";
import { Breakpoint } from "../../store/reducers/application/types";

import s from "./styles.css";
import { useAppSelector } from "../../connect";

const Error = ({ message = "Error" }: { message?: string }) => {
  const breakpoint = useAppSelector<State, Breakpoint>(
    ({ application: { breakpoint } }) => breakpoint
  );
  const isMobile = breakpoint <= Breakpoint.TabletSmall;
  const isTablet = breakpoint <= Breakpoint.DesktopSmall;
  const isWide = breakpoint > Breakpoint.DesktopMedium;

  return (
    <div className={s.root}>
      <Container>
        <Row>
          {!isMobile && !isTablet && <Column size={1} />}
          <Column size={isMobile ? 12 : 3}>
            <h1 className={s.head}>{message}</h1>
            <div className={s.icon}>
              <BiWebcam />
            </div>
            <div className={s.text}>
              <p>It seems like something went wrong</p>
              <p>Let's get you back on track!</p>
            </div>
          </Column>
          <Column size={isMobile ? 12 : isTablet ? 9 : 8}>
            <ThumbsList
              selector={getModelsList}
              limit={isMobile ? 6 : isWide ? 10 : 8}
              perRow={isMobile ? 2 : isWide ? 5 : 4}
            />
          </Column>
        </Row>
      </Container>
      <Container>
        <Row>
          {!isMobile && !isTablet && <Column size={1} />}
          <Column size={isMobile ? 12 : 10}>
            <h2 className={s.subhead}>Explore popular categories:</h2>
            <MainTagsList />
          </Column>
        </Row>
      </Container>
    </div>
  );
};

export default Error;
