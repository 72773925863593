import { NavigateAction } from "../../types";

import { Breakpoint } from "../../store/reducers/application/types";
import { breakpointChanged } from "../../store/reducers/application/actions";
import getCookieService from "../../services/getCookieService";

const readBreakpointAction: NavigateAction = (context) =>
  context
    .service(getCookieService, { name: "breakpoint" })
    .then((breakpoint) =>
      context.dispatch(breakpointChanged(breakpoint as Breakpoint))
    );

export default readBreakpointAction;
