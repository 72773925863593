import React, { PropsWithChildren } from "react";
import cn from "classnames";
import { FaRegEye } from "react-icons/fa";

import Ratio from "../Ratio";
import { Model } from "../../store/reducers/models/types";
import formatKNotation from "../../utils/formatKNotation";
import ModelImage from "../ModelImage";

import s from "./styles.css";

const Thumb = ({
  model,
  children,
  playing,
  main,
}: PropsWithChildren<{ model: Model; playing?: boolean; main?: boolean }>) =>
  model && (
    <Ratio ratio="16/9">
      <div
        className={cn(s.root, {
          [s.online]: model.online,
          [s.main]: main,
        })}
      >
        {children}
        <ModelImage model={model} playing={playing} />
        <div className={s.footer}>
          {main ? <div /> : <div className={s.username}>{model.username}</div>}
          {model.viewersCount && (
            <div className={s.viewers}>
              <FaRegEye />
              &nbsp;{formatKNotation(model.viewersCount, 1)}
            </div>
          )}
        </div>
      </div>
    </Ratio>
  );

export default Thumb;
