import React from "react";
import { Route } from "router6";

import Error from "../../components/Error";

const InternalErrorPage = ({ route }: { route: Route }) => {
  return <Error />;
};

export default InternalErrorPage;
