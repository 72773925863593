// @ts-nocheck
import { NavigateAction } from "../../types";
import { getEnv } from "../../env";

const gaMetaAction: NavigateAction = (context) => {
  const gaId = getEnv("GOOGLE_ANALYTICS_ID");

  if (gaId && typeof window === "undefined") {
    context.head.addScript({
      src: `https://www.googletagmanager.com/gtag/js?id=${gaId}`,
      attributes: { async: true },
    });
    context.head.addInline({
      type: "script",
      content: `window.dataLayer = window.dataLayer || []; function gtag() {dataLayer.push(arguments);} gtag("js", new Date()); gtag("config", "${gaId}");`,
    });
  }
};

export default gaMetaAction;
