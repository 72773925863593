import React, { PropsWithChildren } from 'react';

import s from './styles.css';

const Column = ({ children, size }: PropsWithChildren<{ size?: number }>) => {
  const props = { [`data-w${size}`]: true };

  return (
    <div className={s.root} {...props}>
      {children}
    </div>
  );
};

export default Column;
