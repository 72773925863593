import { createReducer } from "@reduxjs/toolkit";

import {
  tagsLoadingStart,
  tagsLoadingSuccess,
  typedTagsLoadingSuccess,
} from "./actions";
import { Tag, TagsReducerType } from "./types";

const keyByName = (tags): Record<string, Tag> =>
  tags.reduce((result, tag) => ({ ...result, [tag.name]: tag }), {});
const mergeTags = (currentList, nextList) => {
  const currentByTagName = keyByName(currentList);
  const nextByTagName = keyByName(nextList);

  return Object.values({
    ...currentByTagName,
    ...nextByTagName,
  }).map((tag) => (tag.name in nextByTagName ? tag : { ...tag, count: 0 }));
};

const tagsReducer = createReducer(
  { loading: false, list: [], listTyped: [] } as TagsReducerType,
  (builder) => {
    builder
      .addCase(tagsLoadingStart, (state) => {
        state.loading = true;
      })
      .addCase(tagsLoadingSuccess, (state, { payload: { list, replace } }) => {
        state.loading = false;

        if (replace) {
          state.list = list;
        }
      })
      .addCase(
        typedTagsLoadingSuccess,
        (state, { payload: { list, replace } }) => {
          state.loading = false;

          if (replace) {
            state.listTyped = list;
          }
        }
      );
  }
);
export default tagsReducer;
