import { CraqService } from "craq";
import qs from "qs";
export type HttpServicePayload = {
  method?: "get" | "post" | "put" | "delete" | "patch";
  pathname: string;
  query?: Record<string, any>;
  body?: Record<string, any>;
  cookies?: Record<string, any>;
  credentials?: "include" | "omit" | "same-origin";
};

const httpService: CraqService<HttpServicePayload> = (
  context,
  {
    method = "get",
    pathname,
    body,
    query = {},
    credentials = "include",
    cookies,
  }
) => {
  const search = qs.stringify(query, {
    skipNulls: true,
    arrayFormat: "brackets",
  });
  const headers = {};

  if (body) {
    headers["content-type"] = "application/json";
  }

  if (cookies) {
    const cookiesValue = Object.entries(cookies)
      .map(([key, value]) => `${key}=${value}`)
      .join(";");

    if (cookiesValue) {
      headers["cookie"] = cookiesValue;
    }
  }

  return fetch(`${pathname}${search ? "?" : ""}${search}`, {
    method,
    body: body && JSON.stringify(body),
    headers,
    credentials,
  });
};

export default httpService;
