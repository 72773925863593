import { createReducer, Reducer } from "@reduxjs/toolkit";

import { SearchActions, searchStarted, searchSuccess } from "./actions";
import { SearchReducerType } from "./types";

const searchReducer: Reducer<SearchReducerType, SearchActions> = createReducer(
  { models: [], tags: [], search: false } as SearchReducerType,
  (builder) => {
    builder
      .addCase(searchSuccess, (state, { payload }) => {
        const { tags, models } = payload;

        state.search = false;
        state.tags = tags;
        state.models = models;
      })
      .addCase(searchStarted, (state) => {
        state.search = true;
      });
  }
);
export default searchReducer;
