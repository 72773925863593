// @ts-nocheck
import { NavigateAction } from "../../types";
import { getEnv, Env } from "../../env";

const envKeysToExpose: keyof Env = [
  "API_ORIGIN",
  "FRONT_API_ORIGIN",
  "FRONT_ORIGIN",
  "SITE_NAME",
  "VERSION",
  "GOOGLE_ANALYTICS_ID",
];

const envMetaAction: NavigateAction = (context) => {
  if (typeof window === "undefined") {
    context.head.addInline({
      type: "script",
      content: `window.env = ${JSON.stringify(
        envKeysToExpose.reduce((result: Env, key: keyof Env) => {
          result[key] = getEnv(key);

          return result;
        }, {} as Env),
      )};`,
    });
  }
};

export default envMetaAction;
