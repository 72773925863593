import React, { PropsWithChildren } from 'react';
import cn from 'classnames';

import s from './styles.css';

const Chip = ({
  children,
  active,
  badge,
}: PropsWithChildren<{
  active: boolean;
  badge?: string;
}>) => (
  <span
    data-badge={badge}
    className={cn(s.root, { [s.active]: active, [s.badged]: !!badge })}
  >
    {children}
  </span>
);

export default Chip;
