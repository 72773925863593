import React, { PropsWithChildren } from "react";
import { Link } from "router6-react";

import Logo from "../Logo";

import Sections from "./Sections";
import OnlineCount from "./OnlineCount";
import s from "./styles.css";

const Header = ({ children }: PropsWithChildren<{}>) => {
  return (
    <header className={s.root}>
      <div className={s.container}>
        {children}
        <Link className={s.logo} to="home">
          <Logo size={40} />
          <span className={s.sitename}>
            watch<span className={s.accent}>me</span>
          </span>
        </Link>
        <div className={s.body}>
          <Sections />
          <OnlineCount />
        </div>
      </div>
    </header>
  );
};

export default Header;
