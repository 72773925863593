import modelsReducer from './models';
import tagsReducer from './tags';
import applicationReducer from './application';
import searchReducer from './search';
import modalReducer from './modal';
import seoReducer from './seo';

export default {
  models: modelsReducer,
  tags: tagsReducer,
  application: applicationReducer,
  search: searchReducer,
  modal: modalReducer,
  seo: seoReducer
};
