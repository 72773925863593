export type Env = {
  API_ORIGIN: string;
  FRONT_ORIGIN: string;
  FRONT_API_ORIGIN: string;
  SITE_NAME: string;
  VERSION: string;
  GOOGLE_ANALYTICS_ID: string;
};

const env = typeof process === "undefined" ? window.env : process.env;

export const getEnv = <T extends keyof Env>(key: T) => env[key];

export const setEnv = <T extends keyof Env>(key: T, value: string) => {
  Object.assign(env, { [key]: value });
};

export default env;
