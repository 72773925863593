import { CraqService } from "craq";
import cookie from "cookie";

export type SetCookieServicePayload = {
  name: string;
  value: string;
  maxAge?: number;
  path?: string;
};

const setCookieService: CraqService<SetCookieServicePayload> = (
  context,
  { name, value, maxAge, path }
) => {
  const cookieValue = cookie.serialize(name, value, { maxAge, path });

  if (typeof window === "undefined") {
    // @ts-ignore
    context.ctx.res.setHeader("Set-Cookie", cookieValue);
  } else {
    document.cookie = cookieValue;
  }

  return Promise.resolve();
};

export default setCookieService;
