export enum ListingType {
  Pagination,
  InfiniteScroll,
}

export enum Breakpoint {
  MobileSmall = 0,
  MobileMedium = 360,
  MobileLarge = 414,
  TabletSmall = 600,
  Tablet = 768,
  TabletLarge = 960,
  DesktopSmall = 1024,
  DesktopMedium = 1200,
  DesktopHuge = 1368,
}

export type ApplicationReducerType = {
  breakpoint: Breakpoint;
  listingType: ListingType;
};
