function formatKNotation(number: number | string, precision: number) {
  const sanitizedNumber = Number(String(number).replace(/[^0-9.]/g, ""));

  if (sanitizedNumber < 1000) {
    return sanitizedNumber;
  }
  const si = [
    { v: 1e3, s: "K" },
    { v: 1e6, s: "M" },
    { v: 1e9, s: "B" },
    { v: 1e12, s: "T" },
    { v: 1e15, s: "P" },
    { v: 1e18, s: "E" },
  ];
  let index;

  for (index = si.length - 1; index > 0; index--) {
    if (sanitizedNumber >= si[index].v) {
      break;
    }
  }

  return (
    (sanitizedNumber / si[index].v)
      .toFixed(precision)
      .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s
  );
}

export default formatKNotation;
