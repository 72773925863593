import { Genders } from '../constants/genders';
import { Sections } from '../constants/sections';

const map = {
  [Sections.Girls]: Genders.Female,
  [Sections.Men]: Genders.Male,
  [Sections.Couples]: Genders.Couple,
  [Sections.Trans]: Genders.Trans,
};
const mapBack = Object.entries(map).reduce(
  (result, [section, gender]) => ({ ...result, [gender]: section }),
  {},
);

export const sectionsToGender = (section:string) => map[section];
export const genderToSection = (gender:string) => mapBack[gender];
