import { IoMdFemale, IoMdMale } from 'react-icons/io';
import React from 'react';

import Icon from './Icon';
import s from './styles.css';

const CoupleIcon = ({ size }: { size: number }) => (
  <div className={s.couple} style={{ width: size, height: size }}>
    <IoMdFemale />
    <IoMdMale />
  </div>
);

export default Icon(CoupleIcon);
