import { CraqService } from "craq";
import { HttpError } from "craq-server/dist/errors";
import { parse } from "cookie";

import { getEnv } from "../env";

import httpService, { HttpServicePayload } from "./httpService";

type ApiServicePayload = {
  method: string;
  httpMethod?: HttpServicePayload["method"];
  query?: HttpServicePayload["query"];
  body?: HttpServicePayload["body"];
};

const isServer = (context) => "ctx" in context;

const getSessionCookiesFromRequest = (context) => {
  if (isServer(context)) {
    // @ts-ignore
    const { sessionId } = parse(context.ctx.request.headers.cookie || "");

    if (sessionId) {
      return { sessionId };
    }
  }
  return undefined;
};

const apiService: CraqService<ApiServicePayload> = (
  context,
  { method, httpMethod, query = {}, body },
) =>
  context
    .service(httpService, {
      method: httpMethod,
      pathname: `${getEnv(
        typeof window === "undefined" ? "API_ORIGIN" : "FRONT_API_ORIGIN",
      )}/${method}`,
      query,
      body,
      cookies: getSessionCookiesFromRequest(context),
    })
    .then((response) =>
      response.ok
        ? response.json()
        : response.json().then((result) => {
            const e = new HttpError(response.status, { message: result.error });

            return Promise.reject(e);
          }),
    );

export default apiService;
