import { Route } from "router6";

export const getTagsFromParam = (tag: string) => tag.split("+").filter(Boolean);
export const getTagsFromRoute = (route: Route) =>
  getTagsFromParam(String(route.params.tag || ""));
export default (tag: string, currentRoute: Route) =>
  currentRoute.name === "section.tag" &&
  getTagsFromRoute(currentRoute).some((usedTag) =>
    getTagsFromParam(tag).some((t) => t === usedTag)
  );
