import React, { CSSProperties, PropsWithChildren } from "react";
import AspectRatio from "react-aspect-ratio";

import "./styles.css";

const Ratio = ({
  style,
  ratio,
  children,
}: PropsWithChildren<{ style?: CSSProperties; ratio: string | number }>) => (
  <AspectRatio style={style} ratio={ratio}>
    {children}
  </AspectRatio>
);

export default Ratio;
