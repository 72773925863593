import { RouteParams } from "router6";

import { State } from "../store/types";

export const formatWithParams = (
  value: string | ((params: RouteParams, state: State) => string),
  params: RouteParams,
  state: State
) =>
  typeof value === "string"
    ? value.replace(/(?::([^\s]+))/g, (_, name) => String(params[name]))
    : value(params, state);
