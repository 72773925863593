import React from "react";
import { Route } from "router6";

import NotFound from "../../components/NotFound";

const NotFoundPage = ({ route }: { route: Route }) => {
  return <NotFound />;
};

export default NotFoundPage;
