import React, { useCallback, useEffect, useState } from "react";
import cn from "classnames";

import { Model } from "../../store/reducers/models/types";

import s from "./styles.css";

const getModelImageBySource = (model: Model, imageSource: string) => {
  if (!model) {
    return null;
  }

  switch (imageSource) {
    case "image":
      return model.image;
    case "show": {
      const [show] = model.shows || [];

      return show ? show[0].key : null;
    }
    default:
      return null;
  }
};
const ModelImage = ({
  model,
  playing,
}: {
  model: Model;
  playing?: boolean;
}) => {
  const [imageSource, setImageSource] = useState("image");
  const handleError = useCallback(() => {
    if (imageSource === "image") {
      setImageSource("show");
    }
  }, [imageSource]);
  const handleLoad = useCallback(
    ({ target: img }) => {
      if (imageSource === "image" && model.platform === "chaturbate") {
        // detect chaturbate placeholder
        const canvas = document.createElement("canvas");

        canvas.width = 1;
        canvas.height = 1;
        canvas.getContext("2d").drawImage(img, 0, 0, 1, 1);

        const [r, g, b] = canvas.getContext("2d").getImageData(0, 0, 1, 1).data;

        if (r === g && g === b && b === 215) {
          setImageSource("show");
        }
      }
    },
    [model]
  );

  useEffect(() => {
    setImageSource("image");
  }, [model && model.username]);

  return (
    model && (
      <img
        loading="lazy"
        alt={`${model.username} webcam model`}
        crossOrigin={model.platform === "chaturbate" ? "anonymous" : null}
        onLoad={handleLoad}
        onError={handleError}
        className={cn(s.root, { [s.playing]: playing })}
        src={getModelImageBySource(model, imageSource)}
      />
    )
  );
};

export default ModelImage;
