import React from "react";
import { useRouter } from "router6-react";

import { State } from "../../store/types";
import TagsList from "../TagsList";

import s from "./styles.css";
import { useAppSelector } from "../../connect";
import { createSelector } from "reselect";
import { getTagsFromRoute } from "../../utils/isTagLinkActive";

const tagsSelector = createSelector(
  ({ tags }: State) => tags.list,
  (tags) => tags.filter(({ count }) => count > 0).slice(0, 20)
);

const MainTagsList = () => {
  const { currentRoute } = useRouter();
  const tags = useAppSelector(tagsSelector);
  const paramsTags = getTagsFromRoute(currentRoute);
  const usedTypedTag = useAppSelector(({ tags }) =>
    tags.listTyped.find((tag) => paramsTags.includes(tag.name))
  );
  const { section = "models" } = currentRoute.params;

  return (
    <div className={s.root}>
      <TagsList typedTag={usedTypedTag} tags={tags} section={String(section)} />
    </div>
  );
};

export default MainTagsList;
