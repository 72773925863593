import { NavigateAction } from "../../types";
import apiService from "../../services/apiService";
import { modelsCountLoaded } from "../../store/reducers/models/actions";

export const navigateModelsStatsAction: NavigateAction = (context) =>
  context
    .service(apiService, { method: "stats" })
    .then(({ payload: { onlineCount } }) =>
      context.dispatch(modelsCountLoaded(onlineCount))
    );

export default navigateModelsStatsAction;
