import { createAction } from '@reduxjs/toolkit';

import { Model } from './types';

export const modelsLoadingStart = createAction('models loading start');
export const modelsSearchStart = createAction('models search start');
export const modelsSearchSuccess = createAction<Model[]>(
  'models search success',
);
export const modelsFavoritesUpdated = createAction<string[]>(
    'models favorites updated',
);
export const modelsCountLoaded = createAction<number>(
    'models count loaded',
);
export const modelsLoadingSuccess = createAction<{
  list: Model[];
  replace?: boolean;
  page?: number;
  limit: number;
  total: number;
}>('models loading success');

export type ModelsActions =
  | ReturnType<typeof modelsLoadingStart>
  | ReturnType<typeof modelsSearchStart>
  | ReturnType<typeof modelsSearchSuccess>
  | ReturnType<typeof modelsFavoritesUpdated>
  | ReturnType<typeof modelsCountLoaded>
  | ReturnType<typeof modelsLoadingSuccess>;
