import React from 'react';
import { Link } from 'router6-react';

import { SidebarTopItem } from '../types';

import s from './styles.css';

const TopSection = ({ items }: { items: SidebarTopItem[] }) => (
  <ul className={s.list}>
    {items.map(({ routeName, params, title, icon: Icon }) => (
      <li key={title}>
        <Link to={routeName} params={params}>
          <div className={s.listItem}>
            <span className={s.icon}>
              <Icon />
            </span>
            <span className={s.title}>{title}</span>
          </div>
        </Link>
      </li>
    ))}
  </ul>
);

export default TopSection;
