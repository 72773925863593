import { Breakpoint } from "../frontend/store/reducers/application/types";
import changeBreakpointAction from "../frontend/actions/ui/changeBreakpoint";
import { ClientContext } from "craq-client";
import { State } from "../frontend/store/types";

const breakpointHandling = (context: ClientContext<State, any>) => {
  const breakpoints = [
    Breakpoint.MobileSmall,
    Breakpoint.MobileMedium,
    Breakpoint.MobileLarge,
    Breakpoint.TabletSmall,
    Breakpoint.Tablet,
    Breakpoint.TabletLarge,
    Breakpoint.DesktopSmall,
    Breakpoint.DesktopMedium,
    Breakpoint.DesktopHuge,
  ];

  let timeout;
  const handleMediaChange =
    (breakpoint: Breakpoint) =>
    (e, force = false) => {
      if (!e.matches && !force) {
        return;
      }
      clearTimeout(timeout);

      timeout = setTimeout(
        () =>
          context.action(
            changeBreakpointAction,
            e.matches
              ? breakpoint
              : breakpoints[breakpoints.indexOf(breakpoint) - 1]
          ),
        50
      );
    };

  breakpoints.forEach((breakpoint, index) => {
    const nextBreakpoint = breakpoints[index + 1];
    const constraints = [
      `(min-width: ${breakpoint}px)`,
      nextBreakpoint ? `(max-width: ${nextBreakpoint - 1}px)` : null,
    ];
    const media = window.matchMedia(constraints.filter(Boolean).join(" and "));
    const handler = handleMediaChange(breakpoint);

    if (media.matches) {
      handler(media, true);
    }

    if (media.addEventListener) {
      media.addEventListener("change", handler);
    } else if (media.addListener) {
      media.addListener(handler);
    }
  });
};

export default breakpointHandling;
