import React from "react";
import { Link, useRouter } from "router6-react";
import cn from "classnames";

import Couple from "../../GenderIcon/Couple";
import Female from "../../GenderIcon/Female";
import Male from "../../GenderIcon/Male";
import Trans from "../../GenderIcon/Trans";

import s from "./styles.css";

const SECTION_ROUTE = "section";
enum Sections {
  Girls = "girls",
  Men = "men",
  Couples = "couples",
  Trans = "trans",
}

const sections = [
  {
    title: "Girls",
    icon: Female,
    route: SECTION_ROUTE,
    params: { section: Sections.Girls },
  },
  {
    title: "Couples",
    icon: Couple,
    route: SECTION_ROUTE,
    params: { section: Sections.Couples },
  },
  {
    title: "Men",
    icon: Male,
    route: SECTION_ROUTE,
    params: { section: Sections.Men },
  },
  {
    title: "Trans",
    icon: Trans,
    route: SECTION_ROUTE,
    params: { section: Sections.Trans },
  },
];

const HeaderSections = () => {
  const { getActiveRoutes } = useRouter();
  const sectionRoute = getActiveRoutes().find(({ name }) => name === "section");

  return (
    <div className={s.root}>
      {sections.map(({ title, icon: Icon, route, params }) => (
        <Link
          to={route}
          params={params}
          key={title}
          className={cn(s.section, {
            [s.active]: sectionRoute?.params.section === params.section,
          })}
        >
          <span className={s.icon}>
            <Icon size={24} />
          </span>
          <span className={s.title}>{title}</span>
        </Link>
      ))}
    </div>
  );
};

export default HeaderSections;
