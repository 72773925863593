import { Route } from "router6";
import { MouseEvent } from "react";
import { TypedTag } from "../store/reducers/tags/types";
import { getTagsFromRoute } from "./isTagLinkActive";

export default (currentRoute: Route, typedTag?: TypedTag) =>
  (e: MouseEvent<HTMLAnchorElement>, route: Route) => {
    const tagsToKeep = typedTag ? [typedTag.name] : [];

    if (e.shiftKey) {
      tagsToKeep.push(...getTagsFromRoute(currentRoute));

      if (route.name === currentRoute.name && tagsToKeep.length > 0) {
        e.preventDefault();
        const currentTag = String(route.params.tag);
        const included = tagsToKeep.indexOf(String(route.params.tag)) !== -1;

        return {
          route: {
            ...route,
            params: {
              ...route.params,
              tag: (included
                ? tagsToKeep.filter((usedTag) => usedTag !== currentTag)
                : [...tagsToKeep, currentTag].sort()
              ).join("+"),
            },
          },
        };
      }
    }
  };
