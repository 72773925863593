import { createAction } from "@reduxjs/toolkit";

import { Tag, TypedTag } from "./types";

export const tagsLoadingStart = createAction("tags loading start");
export const tagsLoadingSuccess = createAction<{
  list: Tag[];
  replace?: boolean;
}>("tags loading success");
export const typedTagsLoadingSuccess = createAction<{
  list: TypedTag[];
  replace?: boolean;
}>("typed tags loading success");

export type TagsActions =
  | ReturnType<typeof tagsLoadingStart>
  | ReturnType<typeof typedTagsLoadingSuccess>
  | ReturnType<typeof tagsLoadingSuccess>;
