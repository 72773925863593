import { createAction } from '@reduxjs/toolkit';

import { Model } from '../models/types';
import { Tag } from '../tags/types';

export const searchStarted = createAction('search started');
export const searchSuccess = createAction<{ models: Model[]; tags: Tag[] }>(
  'search success',
);

export type SearchActions =
  | ReturnType<typeof searchStarted>
  | ReturnType<typeof searchSuccess>;
