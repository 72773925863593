// @ts-nocheck
import { NavigateAction } from "../../types";
import setCookieService from "../../services/setCookieService";
import getCookieService from "../../services/getCookieService";
import { getEnv } from "../../env";

let isClientSideInitialized = false;

const maintainVersionMetaAction: NavigateAction = (context) => {
  const cookieName = "version";
  const setCookieOptions = {
    name: cookieName,
    maxAge: 5, // 5 seconds
    path: "/",
  };

  if (typeof window === "undefined") {
    return context.service(setCookieService, {
      ...setCookieOptions,
      value: getEnv("VERSION"),
    });
  } else {
    if (isClientSideInitialized) {
      return Promise.resolve();
    }

    isClientSideInitialized = true;

    return context
      .service(getCookieService, { name: cookieName })
      .then((value) => {
        // assume we always have that cookie
        setInterval(() => {
          // support version until tab is closed
          context.service(setCookieService, { ...setCookieOptions, value });
        }, 3000);
      });
  }
};

export default maintainVersionMetaAction;
