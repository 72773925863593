import { RouteDefinition } from "router6";

const routes: RouteDefinition[] = [
  {
    path: "/",
    name: "home",
    config: {
      renderer: "react",
      bundle: "home",
      page: "home",
      layout: "main",
      actions: [
        "models/list",
        "models/stats",
        "tags/list",
        "meta/setup",
        "meta/maintainVersion",
        "meta/ga",
        "meta/env",
        "ui/breakpoint",
      ],
      seo: {
        title: `Free Sex Cams - live erotica`,
        description:
          "Watch absolute free live porn and sex cam shows online. Thousands of live sex cams, adult chats that will make you horny. Find models you would like: girls, guys, shemales or couples doing porn online.",
      },
    },
  },
  {
    name: "section",
    path: "/:section(girls|men|couples|trans|models)",
    config: {
      renderer: "react",
      bundle: "home",
      page: "home",
      layout: "main",
      actions: [
        "models/list",
        "models/stats",
        "tags/list",
        "meta/setup",
        "meta/maintainVersion",
        "meta/ga",
        "meta/env",
        "ui/breakpoint",
      ],
      seo: {
        title: `Free Sex Cams - :section shows live`,
        description:
          "Nude :section online. Explore huge collection of amateurs doing porn online and find someone to cum with! Look behind the curtains!",
      },
    },
    children: [
      {
        name: "tag",
        path: "/:tag",
        config: {
          renderer: "react",
          bundle: "home",
          page: "home",
          layout: "main",
          actions: [
            "models/list",
            "models/stats",
            "tags/list",
            "meta/setup",
            "meta/maintainVersion",
            "meta/ga",
            "meta/env",
            "ui/breakpoint",
          ],
          seo: {
            title: `Free Sex Cams - :section :tag shows live`,
            description:
              "Nude :section online. Explore huge collection of amateurs doing porn online and find someone to cum with! Look behind the curtains!",
          },
        },
      },
    ],
  },
  {
    name: "model",
    path: "/watch/:username@:platform",
    config: {
      renderer: "react",
      bundle: "model",
      page: "model",
      layout: "main",
      actions: [
        "models/single",
        "models/stats",
        "tags/list",
        {
          name: "models/similar",
          options: { clientOnly: true },
          params: { limit: "4" },
        },
        "meta/setup",
        "meta/maintainVersion",
        "meta/ga",
        "meta/env",
        "ui/breakpoint",
      ],
      seo: {
        title: `:username model live show`,
        description: "Watch a free live sex show by :username from :platform",
      },
    },
  },
  {
    name: "404",
    path: "/(.*)",
    config: {
      renderer: "react",
      bundle: "home",
      layout: "main",
      page: "404",
      actions: [
        "models/list",
        "models/stats",
        "tags/list",
        "meta/setup",
        "meta/maintainVersion",
        "meta/ga",
        "meta/env",
        "ui/breakpoint",
      ],
      seo: {
        title: `Not found`,
      },
    },
  },
  {
    name: "5xx",
    path: "/(.*)",
    config: {
      renderer: "react",
      bundle: "home",
      layout: "main",
      page: "500",
      actions: [
        "models/list",
        "models/stats",
        "tags/list",
        "meta/setup",
        "meta/maintainVersion",
        "meta/ga",
        "meta/env",
        "ui/breakpoint",
      ],
      seo: {
        title: `Something went wrong`,
      },
    },
  },
];

export default routes;
